import dayjs from "dayjs";
import { useRouter } from "next/router";
import { FormEvent, useRef, useState } from "react";

import { useAnalytics } from "@atlas/app/context/analytics";
import { config } from "@atlas/app-config";
import { IDENTITY_STATES_US_ENUM } from "@atlas/lib/enum/states/us";
import { request } from "@atlas/lib/request";
import { toast } from "@atlas/lib/toast";
import { BirthdayInput } from "@atlas/ui/BirthdayInput";
import { Button } from "@atlas/ui/Button";
import { Card } from "@atlas/ui/Card";
import { EmailInput } from "@atlas/ui/EmailInput";
import { Icon } from "@atlas/ui/Icon";
import { PhoneInput } from "@atlas/ui/PhoneInput";
import { SelectBox } from "@atlas/ui/SelectBox";
import { TextareaInput } from "@atlas/ui/TextareaInput";
import { TextInput } from "@atlas/ui/TextInput";

const WaitlistForm = ({
  setSent,
  orgId,
}: {
  setSent: React.Dispatch<React.SetStateAction<boolean>>;
  orgId?: string;
}) => {
  const { logEvent } = useAnalytics();
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const [birthday, setBirthday] = useState(dayjs.utc().subtract(18, "years"));
  const [profession, setProfession] = useState<string>();
  const [locationState, setLocationState] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [heard, setHeard] = useState<string>();
  const otherProfessionSelected = profession == "Other (Please Explain)";
  const otherHeard = heard == "Other";
  const router = useRouter();

  const login = async (event: FormEvent) => {
    event.preventDefault();
    const elements = form.current?.elements as any;

    const json = {
      birthday: birthday.toDate(),
      email: elements.email.value.toLowerCase(),
      firstName: elements.firstName.value,
      lastName: elements.lastName.value,
      locationState,
      phone,
      profession: otherProfessionSelected ? elements.professionOther.value : profession,
      heard: otherHeard ? elements.heardOther.value : heard,
      interest: elements.interest.value,
      orgId,
    };

    const res = await request(`${config.api_url}/user/waitlist`, {
      method: "POST",
      json,
      setLoading,
    });
    logEvent("Waitlist Application", {});
    if (res?.complete) setSent(true);
    else if (res?.alreadyExists) {
      toast.info("This email is already signed up. Please log in instead.");
      router.push("/?" + new URLSearchParams({ email: json.email }).toString());
    }
  };

  return (
    <Card className="flex flex-col gap-6 p-8">
      <Icon icon="logo" className="mx-auto h-[50px] w-[120px]" />
      <p className="text-center">
        Thank you for your interest in Atlas! We are currently in private release and have a limited
        number of spots for our service.
        <br />
        <br />
        Join the waitlist to stay informed about our product and your spot on the waitlist.
      </p>

      <form onSubmit={login} ref={form} className="flex flex-col gap-4">
        <EmailInput autoComplete="email" id="email" data-attr="email" defaultValue="" required />
        <TextInput
          label="First Name"
          autoComplete="given-name"
          defaultValue=""
          className="half"
          id="firstName"
          required
        />
        <TextInput
          label="Last Name"
          autoComplete="family-name"
          defaultValue=""
          className="half"
          id="lastName"
          required
        />
        <SelectBox
          label="State"
          options={IDENTITY_STATES_US_ENUM}
          data-attr="validate-state"
          data-testid="validate-state"
          autoComplete="address-level1"
          value={locationState}
          onSelectOption={(val) => setLocationState((val as string) ?? "")}
          required
        />
        <BirthdayInput
          label="Date of Birth"
          granularity="day"
          value={birthday}
          onChange={(newVal) => setBirthday(newVal!)}
          autoComplete="bday"
          minAge={13}
          id="birthday"
          required
        />
        <PhoneInput
          label="Mobile Phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          autoComplete="tel"
          required
          data-attr="phone"
        />
        <SelectBox
          label="What profession are (or were, if retired) you involved in?"
          id="profession"
          value={profession}
          onSelectOption={(option) => setProfession((option as string) || "")}
          required
          options={[
            "Healthcare",
            "Education",
            "Military",
            "Judiciary/Law Enforcement",
            "Fire/EMS",
            "Government",
            "Technology",
            "Construction",
            "Insurance",
            "Transportation",
            "Financial Services",
            "Other (Please Explain)",
          ].map((x) => ({ value: x, label: x }))}
        />
        {otherProfessionSelected ? (
          <TextInput defaultValue="" id="professionOther" required />
        ) : null}
        <SelectBox
          label="How did you hear about us?"
          id="heard"
          value={heard}
          onSelectOption={(option) => setHeard((option as string) || "")}
          required
          options={[
            "Local union or association",
            "News articles",
            "Databreach.com",
            "Through a friend or family",
            "Other",
          ].map((x) => ({ value: x, label: x }))}
        />
        {otherHeard ? <TextInput defaultValue="" id="heardOther" required /> : null}
        <TextareaInput
          label="What is your interest in our privacy services?"
          defaultValue=""
          id="interest"
          className="min-h-[100px] w-full"
        />

        <Button size="wide" formAction="submit" data-attr="submit-waitlist" loading={loading}>
          Join Waitlist
        </Button>
      </form>
    </Card>
  );
};

const FormSubmitted = () => (
  <Card className="flex flex-col items-center justify-center gap-6 p-8">
    <Icon icon="logo" className="h-[50px] w-[120px]" />
    <h2 data-attr="waitlist-applied">You’ve joined the Atlas waitlist!</h2>
    <p>Please check your email inbox.</p>
    <Icon icon="Chill" className="h-[90px] w-[143px]" />
  </Card>
);

export const WaitlistPage = ({ orgId }: { orgId?: string }) => {
  const [sent, setSent] = useState<boolean>(false);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center">
      <div className="w-full max-w-md pt-8">
        {sent ? <FormSubmitted /> : <WaitlistForm orgId={orgId} setSent={setSent} />}
      </div>
      <div className="mt-4 flex flex-col items-center">
        <Button variant="tertiary" href="/" className="w-full">
          Log in to existing account
        </Button>
        <span className="text-gray-500">or</span>
        <Button variant="tertiary" href="/forgot-password" className="w-full">
          Forgot my password
        </Button>
      </div>
    </div>
  );
};
